import { Socket } from 'socket';
import { ReconnectingWebSocket } from 'reconnecting-websocket';
import {
	evtGLOBAL_SEARCH
} from '../../redux/constants/constants';
import { listenEvents } from '../../redux/actions/async/call';

function setupSearchSocketListeners(ws) {
	ws.SetEventListener(evtGLOBAL_SEARCH, (msg, ack)=>{
		console.log("evtGLOBAL_SEARCH",msg, ack);
	});
	
	listenEvents(ws);
}
const newSearchSocket = () => {
	let as = Socket('wss://'+document.location.host+"/search.ws");
    as.WebSocket = ReconnectingWebSocket;
	setupSearchSocketListeners(as);
	return as;
};

var SearchSocket = newSearchSocket();
export default SearchSocket;
