import { I } from './config'
import {
  AT_ALL,
  CT_GENERAL,
  // CT_GENERAL_TOTAL,
  // CT_GENERAL_TOTAL_AGENT,
  // CT_GENERAL_TOTAL_BIG,
  // CT_GENERAL_TOTAL_TIME,
  // CT_DOUGHNUT,
  // CT_FB_RATING,
  // CT_LEADERBOARD,
  // CT_ORG_BAR,
  // CT_SUMMARY_HORZ_BAR,
  // CT_GENERAL_BAR,
  CT_GENERAL_TABLE,
  CT_EXPANDABLE_TABLE,
  // CT_MULTI_TOTAL,
  // CT_LINE,
  // CT_VERT_BAR,
  // CT_PIE,
  // CT_CONTACTSTOPLIST,
  DT_RELATIVE,
  ET_EXCEL,
  RDT_SAME_DAY,
  RT_CHART,
  DT_ERRANDS
} from './constants'

// pseudo cflag
const useNewChartLayout = true
export const PC_NEW_CHART_LAYOUT = useNewChartLayout
export const PC_PIN_NEW_POSITION = useNewChartLayout

export const GRID_ROW_HEIGHT = 20
export const GRID_MARGIN = 10

export const DEF_REPORT_PARAM = {
  reportId: 0,
  reportType: RT_CHART,
  dateType: DT_RELATIVE,
  relativeDateType: RDT_SAME_DAY,
  timeStampFormat: 0,
  timeFormat: 0,
  startTime: '1970/01/01',
  endTime: '1970/01/01',
  areas: '',
  channels: '',
  agentType: AT_ALL,
  groups: '',
  users: '',
  tags: '',
  timeZoneId: 0,
  completeResult: false,
  exportFormat: ET_EXCEL,
  aggregateType: DT_ERRANDS
}

export const DEF_CHARTS = [
  CT_GENERAL
  // CT_EXPANDABLE_TABLE,
  // CT_GENERAL_TABLE
]

// i18n texts ------------------------------------------------------------------
export const TXT_CANCEL = I('Cancel')
export const TXT_DONE = I('Done')
export const TXT_EDIT = I('Edit')
export const TXT_INVALID_CHART_TYPE = I('Invalid chart type ({CHART_TYPE})')
export const TXT_INVALID_REPORT_ID = I('Invalid report id ({REPORT_ID})')
export const TXT_SAVE = I('Save')
export const TXT_UNKNOWN_CHART_TYPE = I('Unknown chart type ({CHART_TYPE})')
export const TXT_USE_PARAMETERS = I('Use parameters')
