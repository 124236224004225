import pathToRegexp from 'path-to-regexp'
import { emptyArray } from '../constants'
import { I } from './config'
import { ADMIN_SMTP, ADMIN_SMTP_AWSSES } from '../path'

export const ONLY_ROOT_EDIT_DEF = false

// i18n texts ------------------------------------------------------------------
export const TXT_ARE_YOU_SURE_DELETE = I("Are you sure you want to delete '{ID}' of {ITEM_TYPE}?")
export const TXT_AUTHORIZED = I('Authorized')
export const TXT_AUTHORIZED_ACCESS_TOKEN = I('Authorized access token')
export const TXT_BEHAVIOUR_ON_MX_FAIL = I('MAIL FROM domain behaviour on MX failure')
export const TXT_DEFAULT = I('Default')
export const TXT_IDENTITY_DOMAIN_EMAIL = I('Identity (domain or email)')
export const TXT_DOMAIN_VERIFICATION_NOTICE = I("Please add the following CNAME records to your DNS:")
export const TXT_FALSE = I('False')
export const TXT_INVALID_DKIM_TOKEN = I('Invalid DKIM token content')
export const TXT_INVALID_RESPONSE_DATA = I('Invalid response data {DATA}')
export const TXT_MAIL_FROM_DOMAIN = I('MAIL FROM domain')
export const TXT_MAIL_FROM_DOMAIN_MX_RECORD = I('MAIL FROM domain MX')
export const TXT_MAIL_FROM_DOMAIN_STATUS = I('MAIL FROM domain status')
export const TXT_MAIL_FROM_DOMAIN_TXT_RECORD = I('MAIL FROM domain TXT')
export const TXT_MAIL_FROM_DOMAIN_VERIFICATION_NOTICE = I("Please add these DNS records: '{MAILFROMMX}', '{MAILFROMTXT}'")
export const TXT_PASSWORD = I('Password')
export const TXT_PORT = I('Port')
export const TXT_RELOAD_DB = I('Reload identity database from AWS SES')
export const TXT_SECURE = I('Secure')
export const TXT_SERVER = I('Server')
export const TXT_SMTP = I('Simple Mail Transfer Protocol (SMTP)')
export const TXT_TRUE = I('True')
export const TXT_UNKNOWN_IDENTITY_TYPE = I('Unknown identity type')
export const TXT_USE_AWS_SES = I('Use AWS SES')
export const TXT_USE_AWS_SES_API = I('Use AWS SES API')
export const TXT_HELPER_AWS_SES_API = I('If the SES API is enabled, then sending will be using API instead of SMTP.')
export const TXT_USERNAME = I('Username')
export const TXT_VERIFICATION_NOTICE = I('A verification email has been sent out. Please check the email inbox and click the link to verify sending through AWS SES. The link will expire in 24h')

export const DKIM_AWS_SES = 'dkim.amazonses.com'

export const SMTP_ROUTES = [ADMIN_SMTP, ADMIN_SMTP_AWSSES]

export const SMTP_ROUTES_MATCH = { exact: true, path: SMTP_ROUTES }

export const pathParams = id => ({ id })

const awssesIdentitiesPathPrams = (id, region, identities) => ({
  id,
  region,
  identities
})

export const toEditPath = pathToRegexp.compile(ADMIN_SMTP)

export const toListPath = () => toEditPath()

export const toSelectAWSSESIdentity = (...args) => pathToRegexp.compile(
  ADMIN_SMTP_AWSSES
)(awssesIdentitiesPathPrams(...args))

export const initData = {
  id: 0,
  areas: emptyArray,
  default: false,
  host: '',
  orgs: emptyArray,
  password: '',
  port: 25,
  secure: false,
  username: '',
  sesapi: false,
}

export const initAWSSES = { id: '', mailFromDomain: '' }

export const initEditValue = { data: initAWSSES, original: initAWSSES }

const mailFromDomainTXTRecord = () => '"v=spf1 include:amazonses.com ~all"'

const mailFromMXRecord = region => `10 feedback-smtp.${region}.amazonses.com`

export const getMailFromMX = (
  region,
  mailFromDomain
) => `${mailFromDomain} MX ${mailFromMXRecord(region)}`

export const getMailFromTXT = mailFromDomain => `${mailFromDomain} TXT ${mailFromDomainTXTRecord()}`

export const tokenToCNAME = (
  domain,
  suffix,
  token
) => `${token}._domainkey.${domain} CNAME ${token}.${suffix}`
