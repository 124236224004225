import { connect } from 'react-redux'
import { changeAdminView } from '../../redux/actions/admin';
import Menu from './menu'
import {
	MY_ERRANDS
	, LIVE_REPORT_EMAIL
	, LIVE_REPORT_CHAT
	, LIVE_REPORT_VOICE
	, LIVE_REPORT_OVERVIEW
} from './constants';
import { changeContext } from '../../redux/actions/workflow';
import { changeView } from '../../redux/actions/statistics';
import {
	activeMenuTitleSelector
	, isMainMenuWorkflowErrand
} from "../../redux/selectors/hmf";
import { getMainMenuById } from "../../redux/selectors/server";
import { push, IsContextSearch } from './utils';

const mapDispatchToProps = (dispatch, props) => ({
	changePage: (link, title, menuId, mainMenuById) => {
		dispatch(push(link))
			.then(() => {
				if (props.pageContext !== 'undefined'
					&& IsContextSearch(props.pageContext)
					&& isMainMenuWorkflowErrand(menuId, mainMenuById)) {
					if (process.env.NODE_ENV !== 'production') {
						console.log('dbg: changeContext trigger from search');
					}
					dispatch(changeContext(MY_ERRANDS));
				} else {
					//todo: make this more generic
					// testing...
					//for example, provide a way to detect the need to change view from backend (like context)
					if(menuId === LIVE_REPORT_EMAIL  || menuId === LIVE_REPORT_CHAT || menuId === LIVE_REPORT_VOICE || menuId === LIVE_REPORT_OVERVIEW) {
						dispatch(changeView(menuId));
					}
					//should only dispatch below when it's admin
					//seems now it always dispatched
					dispatch(changeAdminView(menuId));
				}
			})
			;
	}
});

const mapStateToProps = state => {
	return {
		currentActiveMenu: activeMenuTitleSelector(state)
		, mainMenuById: getMainMenuById(state)
		, menu: state.server.menu
	}
};

const MenuCtnr = connect(mapStateToProps, mapDispatchToProps)(Menu);

export default MenuCtnr;
