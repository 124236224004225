import pathToRegexp from 'path-to-regexp'
import update from 'immutability-helper'
import {
  DEFAULT_FEEDBACK_FONT,
  DEFAULT_FEEDBACK_FONT_COLOR,
  DEFAULT_FEEDBACK_FONT_SIZE,
  DEFAULT_FEEDBACK_FONT_STYLE,
  DEFAULT_FEEDBACK_OUTGOING_POSITION,
  DEFAULT_SATISFACTION_RATING_TEXT,
  FEEDBACK_NO_ACTION,
  FEEDBACK_THANK_YOU_ACTION,
  emptyArray,
  emptyObject
} from '../constants'
import { I } from './config'
import {
  ADMIN_RECEIPT_GREETINGS_SELECT,
  ADMIN_RECEIPT_GREETINGS_EDIT,
  CLOSE_RECEIPTS,
  FEEDBACKS,
  GREETINGS,
  RECEIVE_RECEIPTS
} from '../path'

// Admin Edit Operand
export const AEO_ADD = 'add'
export const AEO_DELETE = 'del'
export const AEO_EDIT = 'edit'
export const AEO_MANUAL = 'manual'

// i18n texts ------------------------------------------------------------------
const TXT_ALL_DAY = I('All day')
const TXT_CUSTOM_DATE = I('Custom date')
const TXT_CUSTOM_TIME = I('Custom time')
const TXT_EVERYTIME = I('Everytime')
const TXT_NONE = I('None')
const TXT_OUT_OF_CUSTOM_DATE = I('Out of custom date')
const TXT_OUT_OF_CUSTOM_TIME = I('Out of custom time')
const TXT_OUT_OF_WORKING_DAYS = I('Out of working days')
const TXT_OUT_OF_WORKING_HOURS = I('Out of working hours')
const TXT_RECEIPT = I('Receipt')
const TXT_WORKING_DAYS = I('Working days')
const TXT_WORKING_HOURS = I('Working hours')
const TXT_CHAT_AUTO_REPLY = I('Chat auto reply')

export const TXT_ACCOUNTS = I('Accounts')
export const TXT_ARE_YOU_SURE_DELETE = I('Are you sure you want to delete {ITEM_TYPE}={ID}?')
export const TXT_ACTIVE = I('Active')
export const TXT_ACTIVATE_INACTIVE_THIS_RECEIPT = I('Activate or inactivate this receipt.')
export const TXT_ALL = I('All')
export const TXT_ALREADY_REGISTERED = I('Already registered')
export const TXT_AREAS = I('Areas')
export const TXT_AREA_REPLIES = I('Area for replies')
export const TXT_BOTH_FIELDS_EMPTY = I('Both {FIRST_FIELD} and {SECOND_FIELD} are empty')
export const TXT_BROWSE = I('Browse')
export const TXT_CLOSE_RECEIPT = I('Close receipt')
export const TXT_CONNECTED_ACCOUNTS = I('Connected accounts')
export const TXT_CONNECTED_AREAS = I('Connected areas')
export const TXT_CONNECTED_ORGS = I('Connected organizations')
export const TXT_CONNECTED_ORGS_AREAS = I('Connected organizations and areas')
export const TXT_CONTACT_BUTTON = I('Contact button text')
export const TXT_CONTACT_BY_EMAIL = I('Text for contact by Email')
export const TXT_CONTACT_BY_PHONE = I('Text for contact by phone')
export const TXT_CONTACT_ME = I('Contact me')
export const TXT_DATE = I('Date')
export const TXT_END_DATE = I('End date')
export const TXT_END_TIME = I('End time')
export const TXT_EXTERNAL_URL = I('External Url')
export const TXT_FEEDBACK = I('Feedback')
export const TXT_FEEDBACK_NAME = I('Feedback name')
export const TXT_FEEDBACK_SETTINGS = I('Feedback settings')
export const TXT_FEEDBACK_RESPONSE = I('Feedback response')
export const TXT_FIELD_NAME_EMPTY = I('Field {FIELD_NAME} is empty')
export const TXT_FONT = I('Font')
export const TXT_FONT_COLOR = I('Font Color')
export const TXT_FONT_SIZE = I('Font Size')
export const TXT_FONT_STYLE = I('Font Style')
export const TXT_FOR_SOCIAL_MEDIA = I('For social media')
export const TXT_IDENTIFIER = I('Identifier')
export const TXT_IMAGE_POSITION = I('Image position')
export const TXT_INVALID_EMAIL = I('Invalid email')
export const TXT_MESSAGE = I('Message')
export const TXT_METER = I('Meter')
export const TXT_NAME = I('Name')
export const TXT_NO_UPLOADED_FILE = I('No uploaded file')
export const TXT_OUTGOING_POSITION = I('Outgoing position')
export const TXT_PREVIEW = I('Preview')
export const TXT_QUESTION = I('Question')
export const TXT_GREETING = I('Greeting')
export const TXT_NO_SELECTION = I('No selection')
export const TXT_NUMBER_DAYS = I('{NUMBER} days')
export const TXT_NUMBER_OF_OPTIONS = I('Number of options')
export const TXT_NUMBER_SELECTED = I('{NUMBER} selected')
export const TXT_OPTION1 = I('Option 1')
export const TXT_OPTION2 = I('Option 2')
export const TXT_OPTION3 = I('Option 3')
export const TXT_OPTION4 = I('Option 4')
export const TXT_OPTION5 = I('Option 5')
export const TXT_ORGS_AREAS = I('Organizations and areas')
export const TXT_RECEIPT_ADDRESS = I('Receipt address')
export const TXT_RECEIPT_FROM_EMAIL = I("Insert receipt's From email address.")
export const TXT_RECEIPT_GREETINGS = I('Receipts and Greetings')
export const TXT_RECEIPT_MESSAGE = I('Receipt message')
export const TXT_RECEIPT_NAME = I('Receipt name')
export const TXT_RECEIPT_ADDRESS_NAME = I('Name in Receipt address')
export const TXT_RECEIPT_RECEIVE = I('Receipt')
export const TXT_RECEIPT_SUBJECT = I('Receipt subject')
export const TXT_RECEIPT_TYPE = I('Receipt type')
export const TXT_REOCCURENCE = I('Reoccurence')
export const TXT_SERVICE_ACCOUNTS_AVAILABILITY = I('Channel {CHANNEL_TYPE} has no account {ACCOUNT_ID}')
export const TXT_SERVICE_NO_ACCOUNT = I('Channel {CHANNEL_TYPE} has no account')
export const TXT_SELECT_AREAS = I('Select areas.')
export const TXT_SELECT_ACCOUNTS = I('Select accounts.')
export const TXT_SELECT_ORGS_AREAS = I('Select organizations and areas.')
export const TXT_SELECT_RECEIPT_TYPE = I('Select receipt type.')
export const TXT_SELECT_TIME_OPTION = I('Select time option for the receipt. Custom option allows date and time to be specified.')
export const TXT_START_DATE = I('Start date')
export const TXT_START_TIME = I('Start time')
export const TXT_SUCCESSFULLY_SENT = I('Successfully sent')
export const TXT_THANK_YOU = I('Thank you')
export const TXT_TIME = I('Time')
export const TXT_UNKNOWN_FILE_SIZE = I('Unknown file size')
export const TXT_UNABLE_TO_UPLOAD_FILE = I('Unable to upload, you must upload an image or a zipped file containing index.html')
export const TXT_UNSUPPORTED_FROM_MORE_THAN_TO = I("Unsupported 'From' value is bigger than 'To'")
export const TXT_UPLOAD_FILE = I('Upload file')
export const TXT_GREETINGS_TYPE = I('Greetings type')

export const DEFAULT_PAGE_SIZE = 10

export const UPLOAD_TYPE_IMAGE = 0
export const UPLOAD_TYPE_TEMPLATE = 1

export const IMAGE_POSITION_TOP = 0
export const IMAGE_POSITION_BOTTOM = 1

export const titleMap = {
  [CLOSE_RECEIPTS]: TXT_RECEIPT,
  [FEEDBACKS]: TXT_FEEDBACK,
  [GREETINGS]: TXT_GREETING,
  [RECEIVE_RECEIPTS]: TXT_RECEIPT
}

const defaultOneOption = {
  answer: DEFAULT_SATISFACTION_RATING_TEXT,
  action: FEEDBACK_THANK_YOU_ACTION,
  identifier: ''
}

export const feedbackDefaultOptions = [
  defaultOneOption,
  defaultOneOption,
  defaultOneOption,
  defaultOneOption,
  defaultOneOption
]

export const feedbackNumberOfResponseOption = [
  { id: 1, value: 1 },
  { id: 2, value: 2 },
  { id: 3, value: 3 },
  { id: 4, value: 4 },
  { id: 5, value: 5 }
]

export const reoccurenceOption = [
  { id: 0, value: TXT_NONE },
  { id: 7, value: I('Weekly') },
  { id: 30, value: I('Monthly') },
  { id: 365, value: I('Yearly') },
  { id: 1, value: I('1 day') },
  { id: 2, value: I('2 days') },
  { id: 3, value: I('3 days') },
  { id: 4, value: I('4 days') },
  { id: 5, value: I('5 days') },
  { id: 6, value: I('6 days') }
]

export const greetingTypeOption = [
  { id: 0, value: TXT_CHAT_AUTO_REPLY },
  { id: 1, value: TXT_OUT_OF_WORKING_HOURS }
]

const DT_OPT_NONE = 0
const DT_OPT_EVERY = 1
const DT_OPT_OUT_WORKING = 2
const DT_OPT_WORKING = 3
export const DT_OPT_OUT_OF_CUSTOM = 4
export const DT_OPT_CUSTOM = 5

export const GREET_OPT_CHAT_AUTO_REPLY = 0;
export const GREET_OPT_OUTSIDE_WORKING = 1;

export const normGreetOption = {
  byId: {
    [GREET_OPT_CHAT_AUTO_REPLY]: TXT_CHAT_AUTO_REPLY,
    [GREET_OPT_OUTSIDE_WORKING]: TXT_OUT_OF_WORKING_HOURS
  },
  allIds: [
    GREET_OPT_CHAT_AUTO_REPLY,
    GREET_OPT_OUTSIDE_WORKING
  ]
}

export const normImagePosition = {
  byId: {
    [IMAGE_POSITION_TOP]: I('Top'),
    [IMAGE_POSITION_BOTTOM]: I('Bottom')
  },
  allIds: [
    IMAGE_POSITION_TOP,
    IMAGE_POSITION_BOTTOM
  ]
}

export const normDateOption = {
  byId: {
    [DT_OPT_NONE]: TXT_NONE,
    [DT_OPT_EVERY]: TXT_ALL_DAY,
    [DT_OPT_OUT_WORKING]: TXT_OUT_OF_WORKING_DAYS,
    [DT_OPT_WORKING]: TXT_WORKING_DAYS,
    [DT_OPT_OUT_OF_CUSTOM]: TXT_OUT_OF_CUSTOM_DATE,
    [DT_OPT_CUSTOM]: TXT_CUSTOM_DATE
  },
  allIds: [
    DT_OPT_EVERY,
    DT_OPT_OUT_WORKING,
    DT_OPT_WORKING,
    DT_OPT_OUT_OF_CUSTOM,
    DT_OPT_CUSTOM
  ]
}

export const normTimeOption = {
  byId: {
    [DT_OPT_NONE]: TXT_NONE,
    [DT_OPT_EVERY]: TXT_EVERYTIME,
    [DT_OPT_OUT_WORKING]: TXT_OUT_OF_WORKING_HOURS,
    [DT_OPT_WORKING]: TXT_WORKING_HOURS,
    [DT_OPT_OUT_OF_CUSTOM]: TXT_OUT_OF_CUSTOM_TIME,
    [DT_OPT_CUSTOM]: TXT_CUSTOM_TIME
  },
  allIds: [
    DT_OPT_NONE,
    DT_OPT_EVERY,
    DT_OPT_OUT_WORKING,
    DT_OPT_WORKING,
    DT_OPT_OUT_OF_CUSTOM,
    DT_OPT_CUSTOM
  ]
}

export const normType = {
  byId: {
    [CLOSE_RECEIPTS]: TXT_CLOSE_RECEIPT,
    [FEEDBACKS]: TXT_FEEDBACK,
    [GREETINGS]: TXT_GREETING,
    [RECEIVE_RECEIPTS]: TXT_RECEIPT_RECEIVE
  },
  allIds: [
    RECEIVE_RECEIPTS,
    GREETINGS,
    CLOSE_RECEIPTS,
    FEEDBACKS
  ]
}

export const tabToShort = {
  [CLOSE_RECEIPTS]: 'closes',
  [FEEDBACKS]: 'feedbacks',
  [GREETINGS]: 'greetings',
  [RECEIVE_RECEIPTS]: 'receives'
}

export const RECEIPT_GREETING_ROUTES = [
  ADMIN_RECEIPT_GREETINGS_SELECT,
  ADMIN_RECEIPT_GREETINGS_EDIT
]

export const RECEIPT_GREETING_ROUTES_MATCH = {
  exact: true,
  path: RECEIPT_GREETING_ROUTES
}

export const pathParams = (tab, edit, id) => {
  const result = { 0: tab }
  if (edit) {
    result[1] = edit
    result.id = id
  }
  return result
}

export const toSelectPath = pathToRegexp.compile(ADMIN_RECEIPT_GREETINGS_SELECT)

export const toEditPath = pathToRegexp.compile(ADMIN_RECEIPT_GREETINGS_EDIT)

const initOption = {
  action: FEEDBACK_NO_ACTION,
  answer: '',
  identifier: ''
}

const initResponsePage = {
  content: '',
  imagePosition: IMAGE_POSITION_TOP,
  uploadType: UPLOAD_TYPE_IMAGE,
  uploadID: '',
  fileName: '',
  fileContent: ''
}

export const DEFAULT_EXTERNAL_URL_PREFIX = 'http://'

export const initData = {
  [CLOSE_RECEIPTS]: {
    id: 0,
    active: false,
    content: '',
    email: '',
    nameInAddress: '',
    name: '',
    socialMedia: false,
    subject: '',
    areas: emptyArray,
    orgs: emptyArray
  },
  [FEEDBACKS]: {
    id: 0,
    active: false,
    content: '',
    position: DEFAULT_FEEDBACK_OUTGOING_POSITION,
    name: '',
    font: DEFAULT_FEEDBACK_FONT,
    fontSize: DEFAULT_FEEDBACK_FONT_SIZE,
    fontColor: DEFAULT_FEEDBACK_FONT_COLOR,
    fontStyle: DEFAULT_FEEDBACK_FONT_STYLE,
    default: false,
    externalUrl: DEFAULT_EXTERNAL_URL_PREFIX,
    options: [
      initOption,
      initOption,
      initOption,
      initOption,
      initOption
    ],
    thankYouPage: initResponsePage,
    contactPage: update(
      initResponsePage,
      {
        $merge: {
          byButton: '',
          byEmail: '',
          byPhone: '',
          errandContainArea: 0
        }
      }
    ),
    registeredPage: initResponsePage,
    contactSentPage: initResponsePage,
    areas: emptyArray,
    orgs: emptyArray
  },
  [GREETINGS]: {
    id: 0,
    active: false,
    content: '',
    name: '',
    areas: emptyArray,
    types: GREET_OPT_CHAT_AUTO_REPLY,
  },
  [RECEIVE_RECEIPTS]: {
    id: 0,
    active: false,
    content: '',
    email: '',
    nameInAddress: '',
    name: '',
    subject: '',
    time: {
      date: DT_OPT_EVERY,
      dateInterval: {
        from: '',
        to: ''
      },
      time: DT_OPT_EVERY,
      timeInterval: {
        from: '',
        to: ''
      }
    },
    accounts: emptyObject,
    areas: emptyArray
  }
}
