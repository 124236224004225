import React from 'react';
import classNames from 'classnames';
import { getChevronIcon } from './helpers';
import onClickOutside from 'react-onclickoutside';
import { withCancellableAction } from '../../containers/hocs';
import { HotkeyLabel } from '../../reactcomponents/Hotkeys';
import { composeWithDisplayName } from '../../reactcomponents/hocs';
import { branch } from "recompose";

class MainMenuBase extends React.Component {
	constructor(props) {
		super(props);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleClickShow = this.handleClickShow.bind(this);
	}
	handleClickOutside() {
		this.props.showListMenu(false);
	}
	handleClickShow() {
		this.props.showListMenu(!this.props.toggleDropDown);
	}
	handleClick = (url, label, id) => {
		this.props.changePage(
			url
			, label
			, id
			, this.props.mainMenuById
		);
	}
	renderLeftNav() {
		const { menu } = this.props;
		if(menu && menu.leftMenu && menu.leftMenu.length > 0) {
			return (
				<ul className="nav-menu">
				{
					menu.leftMenu.map(function(menuItem,i){
						return (
						<MenuItem key={i} item={menuItem} active={false} onClick={this.handleClick}
							hoverable={this.props.hoverable}
							currentActiveMenu={this.props.currentActiveMenu}
						/>);
					}.bind(this))
				}
				</ul>
			);
		}
	}
	renderMainNav () {
		const {toggleDropDown} = this.props;
		var showMenu = "hidden";
		if( toggleDropDown ) {
			showMenu = "";
		}
		return (
			<div className={"jq-selectbox__dropdown " + showMenu}>
				{this.renderLeftNav()}
			</div>
		);
	}
	render() {
		var menuClassName = "main-menu jq-selectbox jqselect sidebar-nav-select focused";
		const {
			toggleDropDown,
			currentActiveMenu
		} = this.props;
		if( toggleDropDown ) {
			menuClassName += " dropdown opened";
		}
		return (
			<div className={menuClassName} hidden={!this.props.visible}>
				<div className="jq-selectbox__select" onClick={this.handleClickShow}>
					<div className="jq-selectbox__select-text">{this.props.customCurrentLabel ? this.props.customCurrentLabel : currentActiveMenu}</div>
					<div className="jq-selectbox__trigger">
						<div className="jq-selectbox__trigger-arrow"></div>
					</div>
				</div>
				<hr hidden={!toggleDropDown} className="menu-divider"/>
				{this.renderMainNav()}
			</div>
		);
	}
};

//toggles menu list with mouse hover. Used on main app breadcrumbs
const withHoverable = Component => props => {
	const handleMouseShow = () => {
		props.showListMenu(true);
	}
	const handleMouseHide = () => {
		props.showListMenu(false);
	}
	return (
		<div className='hover-wrap' onMouseEnter={handleMouseShow} onMouseLeave={handleMouseHide} >
			<Component {...props}/>
		</div>
	);
}

const MainMenu = composeWithDisplayName(
	"MainMenu"
	, branch(
		({ hoverable }) => hoverable
		, withHoverable
	)
	,onClickOutside
)(MainMenuBase)

// https://github.com/Pomax/react-onclickoutside/issues/271
const HOCMenu = withCancellableAction('changePage')((MainMenu));

class Menu extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleShow = this.handleShow.bind(this);
		this.handleChangePage = this.handleChangePage.bind(this);
		this.state = {
			show: false
		};
	}
	handleShow(show) {
		show = !!show;
		this.setState({show});
	}
	handleChangePage(...args) {
		this.setState({show: false});
		this.props.changePage(...args);
	}
	render() {
		return (
			<HOCMenu
				{...this.props}
				showListMenu={this.handleShow}
				toggleDropDown={this.state.show}
				changePage={this.handleChangePage}
			/>
		);
	}
};

const SubMenuAnchor = ({active, hideSubMenu, label, onClick, ...props }) => (
	<a data-qa-id={"menu_with_sub"+label} onClick={onClick} className={classNames({"subMenuActive": !active})}>
		{/* {this.props.item.level>2 ? "-" : ""} --> enable/disable bullets */}
		{label}&nbsp;
		<i className={getChevronIcon(!hideSubMenu)} />
	</a>
);

class MenuAnchor extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(e) {
		const { onClick, ...props } = this.props;
		if (typeof onClick !== "function") {
			return;
		}
		const { id, label, link } = props;
		if(link) {
			onClick(link, label, id);
		}
	}
	render() {
		return (
			<a data-qa-id={"menu_"+this.props.label} onClick={this.handleClick}>
				<HotkeyLabel id={this.props.label} text={this.props.label} hotkey={this.props.hotkey}/>
			</a>
		);
	}
}

export class MenuItem extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleToggle = this.handleToggle.bind(this);
		this.state = {
			hideSubMenu: true
			, activeSubMenuClass: true
		};
	}
	hasSubMenu() {
		return this.props.item.sub && this.props.item.sub.length > 0;
	}
	handleToggle() {
		this.setState({
			hideSubMenu: !this.state.hideSubMenu
			, activeSubMenuClass: !this.state.hideSubMenu
		});
	}
	renderMenuLabel() {
		const { label } = this.props.item;
		if (this.hasSubMenu()) {
			return (
				<SubMenuAnchor
					active={this.state.activeSubMenuClass}
					hideSubMenu={this.state.hideSubMenu}
					label={label}
					hotkey={this.props.item.hotkey}
					onClick={this.handleToggle}
				/>
			);
		}
		return (
			<MenuAnchor
				id={this.props.item.id}
				label={this.props.customCurrentLabel ? this.props.customCurrentLabel : label}
				hotkey={this.props.item.hotkey}
				link={this.props.item.link}
				onClick={this.props.onClick}
			/>
		);
	}
	renderSubMenu() {
		if (!this.hasSubMenu()) {
			return null;
		}
		let hoverable = this.props.hoverable ? this.handleToggle : null;
		const { level, sub } = this.props.item;
		return (
			<ul
				hidden={this.state.hideSubMenu}
				onMouseLeave={hoverable}
				className={classNames(
					"nav-sub-menu"
					, {"multi-level": level > 1}
				)}
			>
			{sub.map((subItem, i) => (
				<MenuItem
					key={i}
					item={subItem}
					onClick={this.props.onClick}
					hoverable={this.props.hoverable}
				/>
			))}
			</ul>
		);
	}
	render() {
		let active = this.props.currentActiveMenu === this.props.item.label;
		return (
			<li id={this.props.item.id ? this.props.item.id : ""} className={classNames({ "active": active })}>
				{this.renderMenuLabel()}
				{this.renderSubMenu()}
			</li>
		);
	}
};

export default Menu;
